import React from 'react';
import { Container, Ratio  } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Whitepaper from '../images/pdf/HPAY-WhitePaper-Q2.pdf';

function whitepaper() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Whitepaper" />
            <Container className="py-5">
                <Ratio aspectRatio="16x9">
                    <embed src={Whitepaper} />
                </Ratio>
            </Container>
        </Layout>
    );
}

export default whitepaper;
